import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ButtonPermission from "../../utilities/ButtonPermission";
import * as Yup from "yup";
import { useFormValidation } from "../../utilities/formValidator";
import { NoteAdd, Home, Add } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, TextField, Box, Typography, Chip } from "@mui/material";
import { getCurrentDate, SnackbarUtilities } from "../../utilities";
import HojaFormOficina from "../../components/Forms/hojaFormalizacion/HojaFormOficina";
import HojaFormPropiedades from "../../components/Forms/hojaFormalizacion/HojaFormPropiedades";
import HojaFormCompradores from "../../components/Forms/hojaFormalizacion/HojaFormCompradores";
import HojaDrawerComponent from "../../components/Drawers/HojaDrawerComponent";
import HojaFormVendedores from "../../components/Forms/hojaFormalizacion/HojaFormVendedores";
import HojaFormDatosEco from "../../components/Forms/hojaFormalizacion/HojaFormDatosEco";
import HojaFormDatosAgentes from "../../components/Forms/hojaFormalizacion/HojaFormDatosAgentes";
import HojaFormRepartos from "../../components/Forms/hojaFormalizacion/HojaFormRepartos";
import { HojaVerifyFormMenu } from "../../components/Forms";
import { createHojaForm, updateHojaForm, getHojaForm } from "../../services";
import { useHojasForm, useUser } from "../../hooks/";
import { estadosHf } from "../../utilities";
// import { DrawerPropiedades } from "../../components";
// import HojaDrawerPropiedades from "../../components/Drawers/HojaDrawerPropiedades";

function HojaFormulario() {
  const { id } = useParams();
  const user = useUser();
  const { code } = user?.user?.rol;
  const { formatUnicData } = useHojasForm();
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [sideOpen, setSideOpen] = useState({
    propiedades: false,
    clientes: false,
    vendedor: false,
  });
  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);

  const handleCloseMenu = async (value) => {
    setAnchorEl2(null);
    if (value) {
      setValues((prevState) => ({
        ...prevState,
        estado: value,
      }));
    }
  };

  const fetchData = async () => {
    const response = await getHojaForm(id);
    let valores = formatUnicData(response);
    setValues(valores);
  };

  const handleClickOpenMenu = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const initialValues = {
    id_sucursal: user?.user?.rol?.permisos?.customViews[
      "HojaDeFormalizacion"
    ]?.includes("Create HojasF")
      ? user?.user?.usuarioDatos?.sucursal_id_ext
      : null,
    sucursal_raw_id: user?.user?.rol?.permisos?.customViews[
      "HojaDeFormalizacion"
    ]?.includes("Create HojasF")
      ? user?.user?.usuarioDatos?.sucursal_id
      : null,
    referencia: null,
    fechaOperacion: getCurrentDate(),
    accion: "",
    propiedades: [
      // {
      //   id_prop: null,
      //   direccion: "",
      //   municipio: "",
      //   provincia: "",
      //   cp: "",
      //   promocion: "",
      //   importe: "",
      // },
    ],
    clientes_hojas: [],
    // comprador_hojas: [
    //   // {
    //   //   id_cliente: "example",
    //   //   rol: "example",
    //   // }
    // ],
    // vendedor_hojas: [
    //   // {
    //   //   id_cliente: "example",
    //   //   rol: "example",
    //   // }
    // ],
    total: null,
    tipoCom: "",
    comisionTotal: "",
    opCompartida: false,
    trabajadores_hojas: [
      {
        id_trabajador: "",
        rol: "",
        vinculo: "",
        porcentaje: "",
        total: "",
      },
    ],
    participantes_hojas: [
      // {
      //   id_contacto: "",
      //   rol: "",
      //   vinculo: "",
      //   porcentaje: "",
      //   total: "",
      // },
    ],
    porLae: 0,
    comisionLae: 0,
    totalComisionNeta: "",
    estado:
      code === "ROOT" || code === "ADMIN"
        ? "validado_por_admin"
        : code === "RDOF"
        ? "validado_por_responsable"
        : "pendiente_validacion_responsable",
    observacion: "",
  };

  const validationSchema = Yup.object().shape({
    id_sucursal: Yup.string().required("Debes seleccionar una Oficina"),
    sucursal_raw_id: Yup.string().required("Debes seleccionar una Oficina"),
    referencia: Yup.string().nullable(),
    fechaOperacion: Yup.string(),
    accion: Yup.string().required("Debe seleccionar una Operación"),
    propiedades: Yup.array()
      .of(
        Yup.object().shape({
          id_prop: Yup.string().nullable(),
          direccion: Yup.string().required("La dirección es obligatoria"),
          municipio: Yup.string().required("El municipio es obligatorio"),
          provincia: Yup.string().required("La provincia es obligatoria"),
          cp: Yup.string().nullable(), // No es requerido
          promocion: Yup.string().nullable(),
          importe: Yup.string()
            .required("Campo obligatorio")
            .min(0, "Debe ser mayor o igual a 0")
            .matches(
              /^[0-9.,]+$/,
              "Debe ser un número en formato válido: 1.234,56"
            ),
          // importe: Yup.string()
          // .transform((value, originalValue) => {
          //   if (typeof originalValue === "number") {
          //     // Convertir el número a formato europeo
          //     return originalValue
          //       .toLocaleString("de-DE", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          //   }
          //   return originalValue;
          // })
          // .required("El importe es obligatorio")
          // .matches(
          //   /^\d{1,3}(\.\d{3})*,\d{2}$/,
          //   "Debe ser un número en formato válido: 1.234,56"
          // )
          // .test(
          //   "is-valid-number",
          //   "Debe ser un número mayor o igual a 0",
          //   (value) => {
          //     if (!value) return false;
          //     const parsedValue = parseFloat(value.replace(/\./g, "").replace(",", "."));
          //     return !isNaN(parsedValue) && parsedValue >= 0;
          //   }
          // )
        })
      )
      .min(1, "Debe haber al menos una propiedad"),
    clientes_hojas: Yup.array()
      .of(
        Yup.object().shape({
          id_cliente: Yup.string(),
          rol: Yup.string(),
        })
      )
      .nullable(),
    total: Yup.string()
      .required("Campo obligatorio")
      .min(0, "Debe ser mayor o igual a 0")
      .nullable(),
    tipoCom: Yup.string()
      .required("Campo obligatorio")
      .min(0, "Debe ser mayor o igual a 0")
      .nullable(),
    comisionTotal: Yup.string()
      .required("Campo obligatorio")
      .min(0, "Debe ser mayor o igual a 0")
      .nullable(),
    trabajadores_hojas: Yup.array()
      .of(
        Yup.object().shape({
          id_trabajador: Yup.string(),
          rol: Yup.string(),
          vinculo: Yup.string().nullable(),
          porcentaje: Yup.number()
            .transform((value, originalValue) =>
              originalValue === "" || isNaN(value) ? null : value
            )
            .nullable()
            .required("El campo no debe estár vacío")
            .min(0, "Debe ser mayor o igual a 0")
            .max(100, "Debe ser menor o igual a 100"),
          total: Yup.number()
            .required("Campo obligatorio")
            .min(0, "Debe ser mayor o igual a 0"),
        })
      )
      .min(1, "Debe haber al menos un trabajador"),
    participantes_hojas: Yup.array().of(
      Yup.object()
        .shape({
          id_contacto: Yup.string(),
          rol: Yup.string(),
          vinculo: Yup.string().nullable(),
          porcentaje: Yup.number()
            .transform((value, originalValue) =>
              originalValue === "" || isNaN(value) ? null : value
            )
            .nullable()
            .test(
              "is-not-null-or-empty",
              "El campo no debe estar vacío",
              (value) => value !== null && value !== ""
            )
            .min(0, "Debe ser mayor o igual a 0")
            .max(100, "Debe ser menor o igual a 100"),
          total: Yup.number().nullable().min(0, "Debe ser mayor o igual a 0"),
        })
        .nullable()
    ),
    opCompartida: Yup.boolean().optional().nullable(),
    porLae: Yup.mixed()
      .transform((value) => (value === "" ? null : value))
      .nullable()
      .test(
        "is-valid",
        "Debe ser un número mayor o igual a 0 o estar vacío",
        (value) => value === null || (typeof value === "number" && value >= 0)
      )
      .optional(),

    comisionLae: Yup.mixed()
      .transform((value) => (value === "" ? null : value))
      .nullable()
      .test(
        "is-valid",
        "Debe ser un número mayor o igual a 0 o estar vacío",
        (value) => value === null || (typeof value === "number" && value >= 0)
      )
      .optional(),
    totalComisionNeta: Yup.number()
      .nullable()
      .min(0, "Debe ser mayor o igual a 0"),
    estado: Yup.string().nullable(),
    observacion: Yup.string().nullable(),
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleReset,
    handleBlur,
    handleSubmit,
    setCustomFieldValue,
    setValues,
  } = useFormValidation({
    initialValues: initialValues,
    validationSchema: validationSchema,
    nullConverter: true,
    onSubmit: async (values, modifiedFields) => {
      if (values) {
        if (!id) {
          let response = await createHojaForm(values);
          const { status } = response;
          if (status == 201) {
            SnackbarUtilities.success(
              "Hoja De Formalización creada Correctamente!"
            );
            setTimeout(() => {
              navigate("/HojaDeFormalizacion");
            }, 3000);
          } else {
            SnackbarUtilities.error(
              "Ha habido un error intentelo de nuevo más tarde"
            );
          }
        } else {
          let response = await updateHojaForm(id, values);
          const { status } = response;
          if (status == 201) {
            SnackbarUtilities.success(
              "Hoja De Formalización editada con exito!"
            );
            setTimeout(() => {
              navigate("/HojaDeFormalizacion");
            }, 3000);
          } else {
            SnackbarUtilities.error(
              "Ha habido un error intentelo de nuevo más tarde"
            );
          }
        }
      } else {
        SnackbarUtilities.error("Error en algun validador del campo");
      }
    },
  });

  useEffect(() => {
    if (id) {
      fetchData();
      setEditMode(true);
    }

    setValues((prevState) => ({
      ...prevState,
      estado:
        code === "ROOT" || code === "ADMIN"
          ? "verificado_por_administrador"
          : code === "RDOF"
          ? "validado_por_responsable"
          : "pendiente_validacion_responsable",
    }));
  }, [id]);
  return (
    <div className="container">
      <div className="top-line">
        <div style={{ textAlign: "start" }}>
          <h3>
            {editMode
              ? "Editar Hoja de Formalización"
              : "Crear Hoja de Formalización"}
          </h3>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <div style={{ display: "flex", flex: 0.5, justifyContent: "end" }}>
          {code == "COOR" ? (
            <></>
          ) : (
            <Button
              variant="outline-dark"
              aria-controls="simple-menu"
              aria-haspopup="true"
              size="small"
              disabled={!id}
              sx={{
                ...styledButton,
                marginRight: "10px",
                backgroundColor: id ? "#33383b" : "initial",
                color: id ? "#c4af6c" : "initial",
              }}
              onClick={(e) => handleClickOpenMenu(e)}
              startIcon={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    sx={{ paddingLeft: "5px", fontSize: "13px !important" }}
                  >
                    Validar
                  </Typography>
                </Box>
              }
              endIcon={<KeyboardArrowDownIcon />}
            />
          )}

          <HojaVerifyFormMenu
            anchorEl={anchorEl2}
            open={open2}
            onClose={handleCloseMenu}
            selectedValue={values.estado}
            estadosHf={estadosHf}
            code={code}
          />
          <ButtonPermission
            variant="contained"
            type="submit"
            endIcon={<NoteAdd />}
            action={editMode ? "edit" : "create"}
            resource="HojaDeFormalizacion"
            sx={{
              color: "#c4af6c",
              backgroundColor: "#33383b",
              height: 40,
              marginBottom: 1,
              borderColor: "transparent",
              "&:hover": {
                color: "#33383b",
                backgroundColor: "#fff",
                borderColor: "#33383b",
              },
            }}
          >
            Guardar
          </ButtonPermission>
        </div>
        {process.env.NODE_ENV == "development" &&
          Object.keys(errors).length > 0 && (
            <div
              className="css-k4qjio-MuiFormHelperText-root"
              style={{ color: "#d32f2f", textAlign: "center" }}
            >
              {JSON.stringify(errors)}
            </div>
          )}

        <div className="middleline">
          <Box
            sx={{
              width: "90%",
              display: "flex",
              flexDirection: "column",
              gap: 3.5,
            }}
          >
            <HojaFormOficina
              editMode={editMode}
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              setCustomFieldValue={setCustomFieldValue}
              setValues={setValues}
            />
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { sm: "1fr" },
                gap: 1.2,
                width: "30%",
              }}
            >
              <Chip
                sx={chipOptions}
                label={estadosHf.find((es) => es.value === values.estado)?.name}
              />
            </Box>

            <HojaFormPropiedades
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              setCustomFieldValue={setCustomFieldValue}
              setValues={setValues}
              setSidePropOpen={setSideOpen}
              sidePropOpen={sideOpen}
            />
            <HojaFormCompradores
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              setCustomFieldValue={setCustomFieldValue}
              setValues={setValues}
              setSidePropOpen={setSideOpen}
              sidePropOpen={sideOpen}
            />
            <HojaFormVendedores
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              setCustomFieldValue={setCustomFieldValue}
              setValues={setValues}
              setSidePropOpen={setSideOpen}
              sidePropOpen={sideOpen}
            />
            <HojaFormDatosEco
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              setCustomFieldValue={setCustomFieldValue}
              setValues={setValues}
              setSidePropOpen={setSideOpen}
              sidePropOpen={sideOpen}
            />
            <HojaFormDatosAgentes
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              setCustomFieldValue={setCustomFieldValue}
              setValues={setValues}
              setSidePropOpen={setSideOpen}
              sidePropOpen={sideOpen}
            />
            <HojaFormRepartos
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              setCustomFieldValue={setCustomFieldValue}
              setValues={setValues}
            />
          </Box>
        </div>
      </form>
      <HojaDrawerComponent
        open={sideOpen}
        setOpen={setSideOpen}
        values={values}
        handleChange={handleChange}
        setCustomFieldValue={setCustomFieldValue}
      />
    </div>
  );
}
const styledButton = {
  backgroundColor: "#33383b",
  color: "#c4af6c",
  "&:hover": {
    backgroundColor: "#c4af6c",
    color: "#33383b",
  },
};
const chipOptions = {
  backgroundColor: "#c4af6c",
  color: "#33383b",
  height: "39.98px",
  borderRadius: "4px",
  "& .MuiChip-label": {
    padding: "8.5px 14px",
    fontFamily: "Arial, sans-serif",
    fontWeight: 550,
  },
};

export default HojaFormulario;
