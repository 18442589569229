import { Box, MenuItem, TextField, Button} from "@mui/material";
import { FaSearch } from "react-icons/fa";
import { MdCleaningServices } from "react-icons/md";

const FiltrosHojasFormalizacion = ({ 
    handleSubmit, 
    inputStyles, 
    errors, 
    values,
    initialValues, 
    handleBlur, 
    handleChange, 
    handleReset, 
    fetchData, 
    user, 
    touched, 
    sucursalMap,
    currentPage 
}) => {
    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="middleline">
                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: { sm: '1fr 1fr' },
                                gap: 2,
                            }}
                        >
                            <div>
                                <TextField
                                    id="demo-simple-select-label"
                                    select
                                    disabled={user?.rol?.permisos?.customViews['HojaDeFormalizacion']?.includes('Create HojasF') ? true : false}
                                    label="Oficina"
                                    name="id_sucursal"
                                    margin="normal"
                                    sx={inputStyles}
                                    value={values.id_sucursal}
                                    size="small"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.id_sucursal && Boolean(errors.id_sucursal)}
                                    helperText={touched.id_sucursal && errors.id_sucursal}
                                >
                                    {sucursalMap &&
                                        <MenuItem value="">
                                            <em>Ninguna</em>
                                        </MenuItem>
                                    }
                                    {sucursalMap ? sucursalMap.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.nombre_sucursal ?? "LAE HOMES - " + option.municipio}
                                        </MenuItem>
                                    )) : <MenuItem disabled>No hay oficinas disponibles</MenuItem>}

                                </TextField>

                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", gap: 6 }}>
                                <TextField
                                    sx={inputStyles}
                                    label="Inicio"
                                    name="fecha.fechaDesde"
                                    type="date"
                                    size="small"
                                    margin="normal"
                                    value={values?.fecha?.fechaDesde}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    // error={touched.fecha?.fechaDesde && Boolean(errors.fecha?.fechaDesde)}
                                    // helperText={touched.fecha?.fechaDesde && errors.fecha?.fechaDesde}
                                    error={Boolean(errors['fecha.fechaDesde']) && Boolean(touched.fecha?.fechaDesde)}
                                    helperText={touched.fecha?.fechaDesde && errors['fecha.fechaDesde']}
                                />
                                <TextField
                                    sx={inputStyles}
                                    label="Fin"
                                    name="fecha.fechaHasta"
                                    type="date"
                                    size="small"
                                    // disabled={values.fechaDesde === "" ? true : false}
                                    margin="normal"
                                    value={values?.fecha.fechaHasta}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    // error={touched.fecha?.fechaHasta && Boolean(errors.fecha?.fechaHasta)}
                                    // helperText={touched.fecha?.fechaHasta && errors.fecha?.fechaHasta}
                                    error={Boolean(errors['fecha.fechaHasta']) && Boolean(touched.fecha?.fechaHasta)}
                                    helperText={touched.fecha?.fechaHasta && errors['fecha.fechaHasta']}
                                />
                            </div>
                            <div >
                                <TextField
                                    id="demo-simple-select-label"
                                    select
                                    label="Operación"
                                    name="accion"
                                    margin="normal"
                                    sx={inputStyles}
                                    value={values.accion}
                                    size="small"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.accion && Boolean(errors.accion)}
                                    helperText={touched.accion && errors.accion}
                                >
                                    {[{ name: "Ninguna", key: "" }, { name: "ALQUILER", key: "ALQUILER" }, { name: "ALQ. OPCIÓN COMPRA", key: "ALQ_OPCION_COMPRA" }, { name: "COMPRA-VENTA", key: "COMPRA_VENTA" }, { name: "TRASPASO", key: "TRASPASO" }].map((option, index) => (
                                        <MenuItem key={index} value={option.key}>
                                            {option.name}
                                        </MenuItem>
                                    ))}

                                </TextField>

                            </div>
                            <div >
                                <TextField
                                    id="demo-simple-select-label"
                                    select
                                    label="Estado Hoja"
                                    name="estado"
                                    margin="normal"
                                    sx={inputStyles}
                                    value={values.estado}
                                    size="small"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={touched.estado && Boolean(errors.estado)}
                                    helperText={touched.estado && errors.estado}
                                >
                                    {[{ name: "Ninguna", key: "" }, { name: "Pendiente validación responsable", key: "pendiente_validacion_responsable" }, { name: "Validado por responsable", key: "validado_por_responsable" }, { name: "Validado por administrador", key: "verificado_por_administrador" }].map((option, index) => (
                                        <MenuItem key={index} value={option.key}>
                                            {option.name}
                                        </MenuItem>
                                    ))}

                                </TextField>

                            </div>
                            <div style={{ display: "flex", flex: 0.5, justifyContent: "start", gap: '10px' }}>

                                <Button
                                    type="submit"
                                    variant="outlined"
                                    sx={{
                                        color: '#c4af6c',
                                        backgroundColor: '#33383b',
                                        height: 40,
                                        borderColor: 'transparent',
                                        '&:hover': { color: '#33383b', backgroundColor: '#fff', borderColor: '#33383b', }
                                    }}
                                >
                                    <FaSearch style={{ color: '#c4af6c', '&:hover': { color: '#33383b' } }} />
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        color: '#33383b',
                                        backgroundColor: '#c4af6c',
                                        borderColor: 'transparent',
                                        '&:hover': { color: '#c4af6c', backgroundColor: '#fff', borderColor: '#33383b', }
                                    }}
                                    onClick={() => {
                                        // setFiltros({});
                                        fetchData({ offset: currentPage, limit: 1000,filtros:initialValues });
                                        handleReset();
                                    }}                        >
                                    <MdCleaningServices style={{ color: '#33383b', '&:hover': { color: '#c4af6c' } }} />
                                </Button>
                            </div>
                        </Box>

                    </div>
                </div>
            </form>
        </>

    );
}

export default FiltrosHojasFormalizacion;